import React from 'react';
import { useState, useEffect } from 'react';
import { Box, Button } from '@mui/material';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';


function TextToImageForm() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [image, setImage] = useState(null);
  const [text, setText] = useState("");
  const [windowWidth, setWidth] = useState(window.innerWidth);



  useEffect(() => {
    setWidth(window.innerWidth);

  }, [])

  function showImage() {
    if (loading) {
        return <h1>Loading...</h1>;
    } else if (error) {
        return <h1>Error: {error}</h1>;
    } else if (image) {
        if (windowWidth < 600) {
            return <img src={image} alt="Generated" style={{ width: "100%", height: "100%" }} />;
        } else {
            return <img src={image} alt="Generated" style={{ width: "550px", height: "550px" }} />;
        }
    }
    }


  async function query(data) {
    const response = await fetch(
      "https://api-inference.huggingface.co/models/runwayml/stable-diffusion-v1-5",
      {
        headers: { Authorization: "Bearer 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" },
        method: "POST",
        body: JSON.stringify(data),
      }
    );
    const result = await response.blob();
    return result;
  }

  return (
    <div style={{ marginTop: "2rem" }}>

      <Box
        sx={{ marginTop: "2rem", marginBottom: "2rem", textAlign: "center" }}
      >
        <TextareaAutosize
          aria-label="minimum height"
          minRows={5}
          placeholder="Enter text here"
          style={{ width: "100%", fontSize: "20px" }}
          value={text}
          onChange={(event) => setText(event.target.value)}
        />
      </Box>

      <Box
        sx={{ marginTop: "2rem", marginBottom: "2rem", textAlign: "center" }}
      >
        <Button
          variant="contained"
          color="secondary"
          style={{ fontSize: "20px" }}
          onClick={() => {
            if (!text) return;
            setLoading(true);
            query({"inputs": text})
              .then((result) => {
                setImage(URL.createObjectURL(result));
                setLoading(false);
              })
              .catch((error) => {
                setError(error);
                setLoading(false);
              });
          }}
        >
          Generate Image
        </Button>
      </Box>

      <Box
        sx={{ marginTop: "2rem", marginBottom: "2rem", textAlign: "center", color: "white", fontFamily: "'Chakra Petch', sans-serif", width: "100%" }}
      >
        {showImage()}
        {image && (
            <Box>
            <a href={image} download="generated-image.png" style={{ marginRight: "10px" }}>
                <Button
                    variant="contained"
                    color="success"
                    style={{ fontSize: "20px" }}
                >
                    Download Image
                </Button>
            </a>
            <a href={image} target="_blank" rel="noreferrer" style={{ marginLeft: "10px" }}>
                <Button
                    variant="contained"
                    color="primary"
                    style={{ fontSize: "20px" }}
                >
                    Open Image
                </Button>
            </a>
            </Box>
        )}
      </Box>
    </div>
  );

}

export default TextToImageForm
