import React, { useEffect } from "react";

import { ReactTyped } from "react-typed";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextToImageForm from "../components/TextToImageForm";

function Home() {
  const message = [
    "🎨 Artistic Genius: You possess an innate talent for creativity and expression.",
    "💡 Innovative Thinker: Your mind is constantly brimming with fresh ideas and innovative concepts.",
    "🌟 Star Creator: Your artwork shines brightly, captivating audiences with its brilliance.",
    "🔥 Passionate Creator: Your passion for art fuels your drive to push boundaries and exceed expectations.",
    "👩‍🎨 Master Crafter: You meticulously craft each pixel, transforming your vision into pixel-perfect masterpieces.",
    "🌈 Color Maestro: Your use of color is vibrant and captivating, breathing life into your creations.",
    "🚀 Limitless Imagination: Your imagination knows no bounds, taking you on exhilarating journeys of creativity.",
    "👾 Pixel Pioneer: You fearlessly explore the frontier of pixel art, pioneering new techniques and styles.",
    "🌟 Inspiration Incarnate: Your work inspires others, sparking creativity and igniting passion in all who behold it.",
    "🎉 Celebrated Artist: Your talent is celebrated far and wide, earning you acclaim and admiration in the art community.",
  ];

  useEffect(() => {}, []);

  return (
    <div style={{ marginTop: "2rem", marginBottom: "2rem" }}>
      <Grid container spacing={2}>
        <Grid item lg={4} mb={4} mt={4} style={{ width: "100%" }}>
          <Box sx={{ width: "100%" }}>
            <img src={require("../assets/art-definition.png")} alt="art-definition" />
          </Box>
        </Grid>
        <Grid item lg={8} mb={8} mt={8} style={{ width: "100%" }}>
          <ReactTyped
            strings={message}
            typeSpeed={40}
            backSpeed={10}
            backDelay={3000}
            loop
            showCursor
            cursorChar="|"
            className="self-typed"
            style={{
              fontSize: "30px",
              color: "white",
              marginBottom: "0px",
              fontFamily: "'Chakra Petch', sans-serif",
            }}
          />
        </Grid>
      </Grid>

      <Box
        sx={{ marginTop: "2rem", marginBottom: "2rem", textAlign: "center" }}
      >
        <ReactTyped
          strings={["Text to Image Generator 🖼️✨"]}
          typeSpeed={40}
          backSpeed={10}
          backDelay={15000}
          loop
          showCursor
          cursorChar="|"
          className="self-typed"
          style={{
            fontSize: "55px",
            color: "white",
            marginBottom: "0px",
            fontFamily: "'Chakra Petch', sans-serif",
          }}
        />

        <TextToImageForm />
      </Box>
    </div>
  );
}

export default Home;
