import React from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Spline from "@splinetool/react-spline";
import Container from '@mui/material/Container';

import Navbar from './components/Navbar';

import Home from './pages/Home';

function App() {
  return (
    <BrowserRouter>
    <Navbar />
    <Spline scene="https://prod.spline.design/MtDQINbPt8B2FisU/scene.splinecode" style={{ width: "100%", height: "100vh", position: "absolute", top: 0, left: 0, zIndex: -1 }} />
    <Container  maxWidth="xl">
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="*" element={<h1>Not Found</h1>} />
    </Routes>
    </Container>

    </BrowserRouter>
  );
}

export default App;
